
























































import {
  goalTeamsBarManualClick,
  goalTeamsBarReleaseNotesClick,
  goalTeamsBarWishlistClick,
} from '@/analytics'
import { QMenu } from 'quasar'
import { Component, Prop, Ref, Vue } from 'vue-property-decorator'

@Component
export default class Menu extends Vue {
  @Prop({
    type: Boolean,
    required: true,
  }) private readonly expanded!: boolean

  @Ref() menu!: QMenu

  private readonly manualLink = 'https://tada.team/manual'
  private readonly releaseNotesLink = 'https://tada.team/all-release-notes'
  private readonly wishlistLink = 'https://wishlist.tada.team'

  show (): void {
    this.menu.show()
  }

  private onManualLinkClick (): void {
    goalTeamsBarManualClick(this.expanded)
    this.onLinkClick(this.manualLink)
  }

  private onReleaseNotesLinkClick (): void {
    goalTeamsBarReleaseNotesClick(this.expanded)
    this.onLinkClick(this.releaseNotesLink)
  }

  private onWishlistLinkClick (): void {
    goalTeamsBarWishlistClick(this.expanded)
    this.onLinkClick(this.wishlistLink)
  }

  private onLinkClick (url: string): void {
    if (this.$q.platform.is.electron) {
      location.href = url
    } else {
      window.open(url)
    }
  }
}
